import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { Button } from '../../../ui-core';
import './styles.scss';
import { useDispatch } from 'react-redux';
import CustomSelect from '../../../customSelect/CustomSelect';
import { sendFeedback } from '../../../../services/api/profileService/profileService';
export const FeedbackPopup = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const [rate, setRate] = useState(-1);
    const [reason, setReason] = useState('');
    const onSend = () => {
        if (data?.callback) {
            let item = answerList.filter(item => item.value == rate)
            sendFeedback({
                feedback: item.length == 0 ? '' : item[0].label,
                other: reason
            }).then(res => {
                data?.callback();
            })
        }
    };
    const onChangeOption = e => {
        console.log(e.target.value);
        setRate(e?.target?.value);
    };
    const answerList = [
        { value: 1, label: 'Not enough variety in workouts' },
        { value: 2, label: 'The workout options don\'t provide enough variety or new challenges.' },
        { value: 3, label: 'Difficulty sticking to a routine' },
        { value: 4, label: 'It\'s hard to stay consistent with my workout schedule.' },
        { value: 5, label: 'Technical issues' },
        { value: 6, label: 'Frequent technical problems with the platform.' },
        { value: 7, label: 'Cost of subscription' },
        { value: 8, label: 'The subscription cost is too high for the value I receive.' },
        { value: 9, label: 'Lack of motivation' },
        { value: 10, label: 'I\'m struggling to stay motivated to work out.' },
        { value: 0, label: 'Other' },
    ];
    const closeHandler = () => {
        onClose && onClose();
    };

    return (
        <>
            <div className="default-modal feedback-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <p className="default-modal-title">We're sorry to see you go!</p>
                <p className="popup-select-description">Please let us know why you're canceling your subscription. This will help us improve our service.</p>
                <p className="popup-select-description">(Please select one option)</p>
                <div className="radio-wrapper">
                    {answerList.map((item, index) => (
                        <div key={index} className="input-group-wrapper">
                            <input
                                type="radio"
                                name={item['value']}
                                value={item['value']}
                                id={item['value']}
                                checked={rate == item['value']}
                                onChange={onChangeOption}
                            />
                            <label htmlFor={item['value']}>{item['label']}</label>
                        </div>
                    ))}
                </div>
                {rate == 0 && (
                    <div className="reason-wrapper">
                        <textarea
                            className="dislike-reason"
                            placeholder="Please tell us what you did not like about the workout."
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                        />
                    </div>
                )}

                <div className="popup-button-group">
                    <Button onClick={onSend} className={'popup-select-submit-not-now'}>
                        {' '}
                        Save
                    </Button>
                    <Button onClick={closeHandler} className={'popup-select-submit-not-now'}>
                        {' '}
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    );
};
