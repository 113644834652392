import types from './workoutsTypes'

const initState = {
    isLoading: false,
    items: [],
    count: 0
}

export const workoutsReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case types.CLEAR_WORKOUTS_LIST:
            return initState
        case types.SAVE_WORKOUTS_LIST:
            return { ...state, ...payload }
        case types.ADD_WORKOUTS_LIST:
            return { ...state, items: state.items.concat(payload) }
        case types.SET_IS_LOADING:
            return { ...state, isLoading: payload }
        default: return initState
    }
}