import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { Button } from '../../../ui-core';
import '../feedbackModal/styles.scss';
import { useDispatch } from 'react-redux';
import CustomSelect from '../../../customSelect/CustomSelect';
import { sendFeedback } from '../../../../services/api/profileService/profileService';
export const FeedbackPopupForVersion = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const [rate, setRate] = useState(-1);
    const [reason, setReason] = useState('');
    const onSend = () => {
        if (data?.callback) {
            try {
                const labels = answerList.filter(item => item.value == rate)
                if (labels.length > 0) {
                    data?.callback(labels[0].label, reason);
                    onClose && onClose()
                }
            } catch (err) {
                console.log(err)
                onClose && onClose()
            }

        }
    };
    const onChangeOption = e => {
        console.log(e.target.value);
        setRate(e?.target?.value);
    };
    const answerList = [
        { value: 1, label: 'Prefer original layout' },
        { value: 2, label: 'Just looking' },
        { value: 3, label: 'Issues with the videos' },
        { value: 4, label: 'Site was glitchy' },
        { value: 5, label: 'Site was too complicated' },
        { value: 0, label: 'Other' },
    ];
    const closeHandler = () => {
        onClose && onClose();
    };

    return (
        <>
            <div className="default-modal feedback-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <p className="default-modal-title">Could you please provide feedback on why you chose to switch back to the classic version?</p>
                <p className="popup-select-description">Your input helps us improve.</p>
                <div className="radio-wrapper" style={{ width: '100%' }}>
                    {answerList.map((item, index) => (
                        <div key={index} className="input-group-wrapper">
                            <input
                                type="radio"
                                name={item['value']}
                                value={item['value']}
                                id={item['value']}
                                checked={rate == item['value']}
                                onChange={onChangeOption}
                            />
                            <label htmlFor={item['value']}>{item['label']}</label>
                        </div>
                    ))}
                </div>
                {rate == 0 && (
                    <div className="reason-wrapper-2">
                        <textarea
                            className="dislike-reason"
                            placeholder="Please tell us what you did not like about the workout."
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                        />
                    </div>
                )}

                <div className="popup-button-group" style={{ width: '100%' }}>
                    <Button onClick={onSend} className={'popup-select-submit-not-now'}>
                        {' '}
                        Save
                    </Button>
                    <Button onClick={closeHandler} className={'popup-select-submit-not-now'}>
                        {' '}
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    );
};
