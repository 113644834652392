const constModal = {
    DEFAULT_MODAL: 'DEFAULT_MODAL',
    BEFORE_VIDEO_MODAL: 'BEFORE_VIDEO_MODAL',
    TIME_VIDEO_MODAL: 'TIME_VIDEO_MODAL',
    VIDEO_MODAL: 'VIDEO_MODAL',
    INFO_MODAL: 'INFO_MODAL',
    CARD_MANAGER_MODAL: 'CARD_MANAGER_MODAL',
    RATE_MODAL: 'RATE_MODAL',
    RATE_TEST_MODAL: 'RATE_TEST_MODAL',
    FEEDBACK_MODAL: 'FEEDBACK_MODAL',
    FEEDBACKVERSION_MODAL: 'FEEDBACKVERSION_MODAL',
    FREECHANGE_MODAL: 'FREECHANGE_MODAL',
    STARTCHANGE_MODAL: 'STARTCHANGE_MODAL',
    FITTEST_MODAL: 'FITTEST_MODAL',
    ADS_MODAL: 'ADS_MODAL',

};
export default constModal;
