import './types';
import constModal from './types';
import DefaultModal from './component/defaultModal/defaultModal';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from './redux/modalActions';
import { useEffect } from 'react';
import GetBeforeVideoModal from './component/getBeforeVideoModal/getBeforeVideoModal';
import TimeVideoModal from './component/timeVideoModal/timeVideoModal';
import VideoModal from './component/videoModal/videoModal';
import InfoModal from './component/infoModal/infoModal';
import CardManagerModal from './component/cardManagerModal/cardManagerModal';
import { RatePopUp } from './component/ratePopup/ratePopup';
import { FeedbackPopup } from './component/feedbackModal/feedbackPopup';
import { FreeChallengeModal } from './component/freeChallengeModal/freeChallengeModal';
import { StartChallengeModal } from './component/startChallengeModal/startChallengeModal';
import { RatePopUpForTest } from './component/ratePopupForTest/ratePopupForTest';
import { FitTestModal } from './component/fitTestModal/fitTestModal';
import { FeedbackPopupForVersion } from './component/feedbackModalForVersion/feedbackPopupForVersion';
import { AdsModal } from './component/adsModal/adsModal';
const Modal = () => {
    const { typeModal, dataModal, statusModal } = useSelector(store => store.modalReducer);
    const dispatch = useDispatch();
    const _onClose = () => {
        dispatch(closeModal());
    };
    useEffect(() => {
        if (statusModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            setTimeout(() => {
                document.body.style.overflow = 'auto';
            }, 0);
        }
    }, [statusModal]);

    const getWindow = () => {
        console.log(typeModal, 'type');
        switch (typeModal) {
            case constModal.RATE_MODAL:
                return <RatePopUp onClose={_onClose} data={dataModal} />;
            case constModal.RATE_TEST_MODAL:
                return <RatePopUpForTest onClose={_onClose} data={dataModal} />;
            case constModal.FEEDBACK_MODAL:
                return <FeedbackPopup onClose={_onClose} data={dataModal} />;
            case constModal.FEEDBACKVERSION_MODAL:
                return <FeedbackPopupForVersion onClose={_onClose} data={dataModal} />;
            case constModal.FREECHANGE_MODAL:
                return <FreeChallengeModal onClose={_onClose} data={dataModal} />;
            case constModal.STARTCHANGE_MODAL:
                return <StartChallengeModal onClose={_onClose} data={dataModal} />;
            case constModal.FITTEST_MODAL:
                return <FitTestModal onClose={_onClose} data={dataModal} />;
            case constModal.ADS_MODAL:
                return <AdsModal onClose={_onClose} data={dataModal} />;
            case constModal.CARD_MANAGER_MODAL:
                return <CardManagerModal onClose={_onClose} data={dataModal} />;
            case constModal.DEFAULT_MODAL:
                return <DefaultModal onClose={_onClose} data={dataModal} />;
            case constModal.BEFORE_VIDEO_MODAL:
                return <GetBeforeVideoModal onClose={_onClose} data={dataModal} />;
            case constModal.TIME_VIDEO_MODAL:
                return <TimeVideoModal onClose={_onClose} data={dataModal} />;
            case constModal.VIDEO_MODAL:
                return <VideoModal onClose={_onClose} data={dataModal} />;
            case constModal.INFO_MODAL:
                return <InfoModal onClose={_onClose} data={dataModal} />;
            default:
                return null;
        }
    };
    return <div className={statusModal ? 'modal' : 'modal-close'}>{getWindow()}</div>;
};
export default Modal;
