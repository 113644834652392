import { Button } from '../../../ui-core';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { logError } from '../../../../utils/globalUtils';

const DefaultModal = ({ onClose, data = {}, t }) => {
    const {
        title = '',
        description = '',
        button_cancel_text = '',
        button_ok_text = '',
        callback_button_cancel,
        callback_button_ok,
    } = data;

    const onClickCancel = () => {
        try {
            onClose();
            callback_button_cancel && callback_button_cancel();
        } catch (error) {
            logError(error, 'defaultModal.js', 'onClickCancel');
        }
    };

    const onClickOk = () => {
        try {
            onClose();
            callback_button_ok && callback_button_ok();
        } catch (error) {
            logError(error, 'defaultModal.js', 'onClickOk');
        }
    };

    return (
        <div className="default-modal">
            <img onClick={onClose} src={imgClose} className="default-modal-close" alt={'close'} />
            <p className="default-modal-title">{t(title)}</p>
            <p className="default-modal-description">{t(description)}</p>
            <div className="default-modal__container">
                {button_cancel_text != '' && <Button className="default-modal__button default-modal__button-cancel" onClick={onClickCancel} onTouchEnd={onClickCancel}>
                    {t(button_cancel_text)}
                </Button>}
                <Button className="default-modal__button default-modal__button-ok" onClick={onClickOk} onTouchEnd={onClickOk}>
                    {t(button_ok_text)}
                </Button>
            </div>
        </div>
    );
};

export default withTranslation()(DefaultModal);
