import { post, get, put, remove } from '../apiService';

export const getSubscribes = () => {
    return get('/payment/stripe/subscribes')
}

export const getUserSubscription = () => {
    return get('/payment/stripe')
}
export const testUserStripe = () => {
    return get('/payment/stripe/testFF')
}
export const getApiKey = () => {
    return get('/payment-setting/stripe/public-key')
}

export const attachPaymentMethod = (paymentMethodId, metaEventId) => {
    return post('/payment/stripe/payment-method', { paymentMethodId, metaEventId })
}

export const detachPaymentMethod = (paymentMethodId) => {
    return remove('/payment/stripe/payment-method', { paymentMethodId })
}

export const getUsersPaymentMethod = () => {
    return get('/payment/stripe/payment-method')
}
export const createFreeSubscribe = () => {
    return post('/payment/stripe/free', {});
};
export const subscribe = ({ priceId }) => {
    return post('/payment/stripe', { priceId })
}

export const unsubscribe = (subscribeId, status) => {
    return put('/payment/stripe', { subscribeId })
}
